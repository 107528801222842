.hero{
    display: flex;
    justify-content: space-between;
}

.hero__left{
    padding: 2rem 1.5rem;
    flex: 3;
}

.hero__right{
    background-color: var(--orange);
    flex: 1;
    position: relative;
    
}

.hero_add{
    margin-top: 5rem;
    background-color: #363d42;
    border-radius: 4rem;
    width: fit-content;
    padding: 1rem 1.3rem;
    text-transform: uppercase;
    color: white;
    position: relative;
    z-index: 19;
    

}
.hero_add > span{
    
    z-index: 10;
}
.hero_add > div{
    position: absolute;
    background-color: var(--orange);
    width: 5.4rem;
    height: 80%;
    left: 0.8rem;
    border-radius: 3rem;
    z-index:-1 ;
    top: 0.3rem;
    
}

.hero-text{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 4.5rem;
    text-overflow: inherit;
}

.hero-text > div:nth-of-type(3){
    font-size: 1rem;
    font-weight: 200;
    text-transform: none;
    letter-spacing: 1px;
    width: 80%;
}

.btn{
    background-color: white;
    padding: 0.5rem ;
    font-weight: bold;
    transition: 300ms all;
    cursor: pointer;
    margin-top: 2rem;
   text-align: center;
   
    
}

.hero-button > :nth-child(1){
color: white;
background-color: var(--orange);
width: 8rem;
border: none;
}
.hero-button > :nth-child(2){
    color: white;
    background-color: transparent;
    width: 8rem;
    border: 2px solid var(--orange);
    margin-left: 1rem;
    }

    .hero__right > .btn{
       position: absolute;
       right: 3rem;
       top: 2rem;
         
    }

    .heart-rate{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        background-color: var(--darkGrey);
        width: fit-content;
        padding: 1rem;
        align-items: flex-start;
        border-radius: 5px;
        position: absolute;
        right: 4rem;
        top: 7rem;
    }
    .heart-rate > img{
        width: 2rem;
    }
    .heart-rate > :nth-child(2){
         color: var(--gray);
    }
    .heart-rate > :nth-child(3){
        color: white;
        font-size: 1.5rem;
    }

    .hero-image{
        position: absolute;
        top: 10rem;
        right: 8rem;
       
        width: 23rem;

    }
    .hero-image-back{
        position: absolute;
        top: 4rem;
        right: 20rem;
       z-index: -1;
        width: 15rem;

    }

    .calories{
        display: flex;
        gap: 2rem;
        background-color: var(--caloryCard);
        border-radius: 5px;
        padding: 1rem;
        width: fit-content;
        position: absolute;
        top: 32rem;
        right: 28rem;
    }

    .calories > img{
        width: 3rem;
    }

    .calories  >  div{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .calories > div > :nth-child(1){
        color: var(--gray);
    }
    .calories > div > :nth-child(2){
        color: white;
        font-size: 1.5rem;
    }

.blur-h {
    width: 22rem;
    height: 39rem;
    left: 0;
}

@media screen and (max-width:768px) {
  .hero{
    flex-direction: column;
  } 
  .blur-h{
    width: 14rem;
  }
  .hero_add{
    margin-top: 0;
    font-size: small;
    align-self: center;
    transform: scale(0.8);
  }
  .hero-text{
    font-size:xx-large ;
    align-items: center;
    justify-content: center;
  }
  .hero-text>div:nth-of-type(3){
     font-size: small;
     font-weight: 200;
     letter-spacing: 1px;
     text-align: center ;
  }
  .hero-button{
    justify-content: center;
  }
  .figures>div>span:nth-of-type(1){
    font-size: large;
    
  }
  .figures>div>span :nth-of-type(2){
    font-size: small;

  }
  .hero__right{
    position: relative;
    background: none;

  }
  .heart-rate{
    left: 1rem;
    top: 2rem;
  }
  .calories{
    position: relative;top: 5rem;
    left: 2rem;
  }
  .calories>img{
    width: 2rem;
  }
  .calories > div > :nth-child(2){
    color: white;
    font-size: 1rem;
  }
  .hero-image{
    position: relative;
    width: 15rem;
    left: 7rem;
    top: 4rem;
    align-self: center;

  }
  .hero-image-back{
    width: 15rem;
    left: 2rem;
    top: 0rem  ;
  }

}





