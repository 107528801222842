.header {
    display: flex;
    justify-content: space-between;
}

.logo {
    height: 3rem;
    width: 10rem;
    transform: translateX(-100rem);
    animation: slideIn .5s forwards;
}

.header-menu {
    list-style: none;
    display: flex;
    gap: 2rem;

}

.header-menu>li {
    cursor: pointer;
}

.header-item {
    position: relative;
    transform: translateX(100rem);
    animation: slideIn .5s forwards;
}

.header-item:nth-child(1) {
    animation-delay: 0s;
}

.header-item:nth-child(2) {
    animation-delay: 0.5s;
}

.header-item:nth-child(3) {
    animation-delay: 1s;
}

.header-item:nth-child(4) {
    animation-delay: 1.5s;
}

.header-item:nth-child(5) {
    animation-delay: 2s;
}

.header-link {
    text-decoration: none;
    color: white;


}

.header-link::before,
.header-link::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: var(--orange);
    left: 0;
    transform: scaleX(0);
    transition: all .5s;
}

.header-link::before {
    transform-origin: left;
    top: -5px;
}

.header-link::after {
    bottom: -5px;
    transform-origin: right;
}

.header-link:hover::before,
.header-link:hover:after {
    transform: scaleX(1);
}

@keyframes slideIn {
    from {}

    to {
        transform: translateX(0);
    }

}

@media screen and (max-width: 768px) {
    .header>:nth-child(2){
       position: fixed;
       right: 2rem;
       z-index: 99; 
    }
    .header-menu{
        flex-direction: column;
        background-color: var(--appColor);
        padding: 2rem;
    }
    
}